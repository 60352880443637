import React, { useState, useEffect } from "react";
import AboutAreaFive from "../components/AboutAreaFive";
import BannerFive from "../components/BannerFive";
import FooterFour from "../components/FooterFour";
import TeamAreaThree from "../components/TeamAreaThree";
import WorkProcessFour from "../components/WorkProcessFour";
import FaqAreaOne from "../components/FaqAreaOne";
import WorkProcessOne from "../components/WorkProcessOne";
import NavBar from "../components/NavBar";
import Preloader from "../components/Preloader";

import { Helmet } from "react-helmet";

const HomeFive = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay (e.g., API fetch) for 2 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {/* Conditionally render the Preloader based on the isLoading state */}
      {isLoading ? (
        <Preloader />
      ) : (
        <div>
          <Helmet>
            <title>
              Galileo Data - High-Quality B2B Email Lists & Data Solutions
            </title>
            <meta
              name="description"
              content="Galileo Data offers premium B2B email lists and data solutions to enhance your marketing efforts. Access targeted, high-quality data across industries to drive successful campaigns and growth."
            />
            <meta
              name="keywords"
              content="b2b data providers,data driven marketing, b2b database, best way to market your business, business lists for marketing, data providers, data marketing, email marketing database, lead generation companies, business contact list, generate leads for business, b2b list providers, lead generation services,  b2b lead generation, b2b lead generation companies "
            />
            <link rel="canonical" href="https://galileodata.us/" />
          </Helmet>

          {/* Navigation Bar */}
          <NavBar />

          {/* Banner Five */}
          <BannerFive />

          {/* About Area Five */}
          <AboutAreaFive />

          {/* Work Process Four */}
          <WorkProcessFour />

          {/* FAQ Area One */}
          <FaqAreaOne />

          {/* Team Area Three */}
          <TeamAreaThree />

          {/* Work Process One */}
          <WorkProcessOne />

          {/* Footer Four */}
          <FooterFour />
        </div>
      )}
    </>
  );
};

export default HomeFive;
