import React from 'react'
const Preloader = () => {
  return (
    <>
<div className='preloader'>
<div className='preloader-align'>
        <div className='preloader-icon'/>
        &nbsp;
        &nbsp;
        &nbsp;
    <div className='preloader-letters'/>
</div>

</div>
      
    </>
  )
}

export default Preloader
