import React from "react";
import ContactMain from "../components/ContactMain";
import NavBar from "../components/NavBar";
import FooterFour from "../components/FooterFour";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>
          Get in Touch | Galileo Data - Your Trusted Data Solutions Partner
        </title>
        <meta
          name="description"
          content="Get in touch with Galileo Data, your trusted partner for web design, development, and digital marketing services. Reach out to us today to discuss your project needs and elevate your online presence"
        />
        <meta
          name="keywords"
          content="web design USA,web development company USA, digital marketing services, Galileo Data contact, custom web development, Contact Us"
        />
        <link rel="canonical" href="https://galileodata.us/contact-us/" />
      </Helmet>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      {/* <Breadcrumb title={"Contact us"} /> */}

      {/* Contact Main */}
      <ContactMain />

      {/* Footer One */}
      <FooterFour />
    </>
  );
};

export default Contact;
